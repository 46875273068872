import { isLuhn } from './common';
import { daysInMonth } from './utils';

export const SWEDISH_MOBILE_NUMBER_REGEX = /^(07)(\d{8})$/;
export const SWEDISH_MOBILE_NUMBER_WITH_COUNTRY_CODE_REGEX = /^(46)(\d{9})$/;
export const SWEDISH_POSTAL_CODE_REGEX = /^\d{3}\s?\d{2}$/;
export const VALID_POSTAL_CODE_REGEX = /^(?!0|10|20|40)\d*\s?\d*$/;
// SWEDISH_ADDRESS_REGEX accepts all these special characters ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿıŒœŠšŸŽž
export const SWEDISH_ADDRESS_REGEX = /^(?:[a-zA-Z\u00c0-\u017e]+\s){1,4}\d{1,3}\w?/;
export const SWEDISH_APARTMENT_NUMBER_REGEX = /^\d{4}$/;
export const SWEDISH_PROPERTY_IDENTIFIER_REGEX = /^(?:[a-zA-ZåäöÅÄÖ]+\s){1,4}\d{1,4}(?::\d{1,4})?$/;
export const DECIMAL_REGEX = /^[0-9]*([\.\,][0-9]{0,2})?$/;

export function swedishSSN(input: string) {
    if (!input || input.length < 1 || !input.replace) {
        return false;
    }
    const ssn = input.replace(/\D/g, '');

    // check length
    if (ssn.length !== 12) {
        return false;
    }

    // We remove the first two characters of the 12 character long SSN.
    const strippedSSN = ssn.slice(2);

    // Will return false if the ssn proves invalid by the algo, else continue
    if (!isLuhn(strippedSSN)) {
        return false;
    }

    // Check snn day, month and year for correct date
    const day = Number(ssn.slice(6, 8));
    const month = Number(ssn.slice(4, 6));
    const year = Number(ssn.slice(0, 4));
    const currentYear = new Date().getFullYear();
    return year < currentYear && year > 1900 && month > 0 && month <= 12 && day > 0 && day <= daysInMonth(month, year);
}

/**
 * Accepts a SSN and returns true if the supplied SSN is at least 18 years old.
 */
export function swedishSSNAtLeast18(ssnArg: string) {
    let ssn = ssnArg;

    if (typeof ssn !== 'string') {
        const error = new Error('The supplied value must be of type string or number');
        throw error;
    }

    const year = ssn.substring(0, 4);
    const month = (parseInt(ssn.substring(4, 6), 10) - 1).toString();
    const day = parseInt(ssn.substring(6, 8), 10).toString();
    const ageDate = new Date(Date.now() - new Date(Number(year), Number(month), Number(day)).getTime());
    const res = Math.abs(ageDate.getUTCFullYear() - 1970);
    return res >= 18;
}

/**
 * Plusgiro number input validation
 */
export function pgValidation(input: string) {
    if (typeof input !== 'string') {
        return false;
    }

    // Must be 1 to 7 digits, one dash, and 1 more digit
    return /^[0-9]{1,7}-[0-9]$/.test(input);
}

/**
 * Bankgiro number input validation
 */
export function bgValidation(input: string) {
    if (typeof input !== 'string') {
        return false;
    }

    // Must be 3 to 4 digits, one dash, and 4 more digits
    return /^[0-9]{3,4}-[0-9]{4}$/.test(input);
}

export function companyRegistrationNumber(input: string) {
    if (!input || typeof input !== 'string' || input.length !== 10) {
        return false;
    }

    // Will return false if the ssn proves invalid by the luhn algorithm, else continue
    return isLuhn(input);
}

export function companyRegistrationNumberAB(input: string) {
    if (!companyRegistrationNumber(input)) {
        return false;
    }

    // AB has month >= 20
    if (Number(input.slice(2, 4)) < 20) {
        return false;
    }

    // First digit in an AB registration number is a 5
    if (Number(input.slice(0, 1)) !== 5) {
        return false;
    }

    return true;
}

export function companyRegistrationNumberHB(input: string) {
    if (!companyRegistrationNumber(input)) {
        return false;
    }

    // HB has month >= 20
    if (Number(input.slice(2, 4)) < 20) {
        return false;
    }

    // First digit in an HB/KB registration number is a 9
    if (Number(input.slice(0, 1)) !== 9) {
        return false;
    }

    return true;
}

/**
 * Checks if a number is a valid swedish mobile number e.g.
 * +46XXXXXXXX, 46XXXXXXXX or 07XXXXXXXX.
 */
export function isValidSwedishMobileNumber(input: string) {
    let number = input.toString();
    if (number.startsWith('+')) {
        number = number.substr(1, number.length);
    }

    return SWEDISH_MOBILE_NUMBER_REGEX.test(number) || SWEDISH_MOBILE_NUMBER_WITH_COUNTRY_CODE_REGEX.test(number);
}

/**
 * Checks if an input value is a valid Swedish postal code and doesn't start with 10,20 and 40.
 * Example: 182 73, 18273, 10000 (the space is optional)
 *
 */

export function isValidSwedishPostalCode(input: string) {
    return SWEDISH_POSTAL_CODE_REGEX.test(input) && VALID_POSTAL_CODE_REGEX.test(input);
}

/**

* Checks if the input is a valid swedish address

* Example: 
* Drottninggattan 10, Stockholm
* Vasagatan 4

*/

export function isValidSwedishAddress(input: string) {
    return SWEDISH_ADDRESS_REGEX.test(input);
}

/**
 * Checks if an input value is a valid Swedish apartment number.
 * Example: 1001, 1103, 2006 etc
 *
 */

export function isValidSwedishApartmentNumber(input: string) {
    return SWEDISH_APARTMENT_NUMBER_REGEX.test(input);
}

/**
 * Checks if an input value is a valid Property identifier.
 * Example: LYCKSELE PAULIDEN 12:1, ÖSTERSUND HANDOG 1,
 *
 */

export function isValidPropertyIdentifier(input: string) {
    return SWEDISH_PROPERTY_IDENTIFIER_REGEX.test(input);
}

/**
 * Checks if an input has maximum 2 decimals
 * Example 4, 0.4, 0.45 or 0,45 etc
 */

export function isValidInputWithMaxTwoDecimals(input: string) {
    return DECIMAL_REGEX.test(input);
}
