import { useReducer, useCallback, Reducer, useLayoutEffect, useEffect } from 'react';
import { FieldValue, FieldMap, FieldInputMap } from './types';
import { ExecuteValidatorOutcome, executeValidators } from './validators';
import {
    convertFieldInputsToFields,
    runFormatter,
    doesErrorExist,
    updateField,
    gatherFieldValues,
    resetFields,
} from './utils';
import { isUndefinedOrNull } from './utils/isUndefinedOrNull';
import { Effect, createEffect, isEffectIdle } from './useSideEffects';
import { isEmpty } from './utils/isEmpty';
import { callAll } from './utils/callAll';
import { SideEffects, useSideEffects } from './useSideEffects';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export interface State {
    status: 'idle' | 'validating' | 'error';
    effects: Effect[];
    fields: FieldMap;
}

interface ChangeEvent {
    type: 'CHANGE';
    name: string;
    value: FieldValue;
}

interface CommitEvent {
    type: 'COMMIT';
    name: string;
    value?: FieldValue;
}

interface FastCommitEvent {
    type: 'FAST_COMMIT';
    name: string;
    value: FieldValue;
}

interface SetFieldValidationEvent {
    type: 'SET_FIELD_VALIDATION';
    name: string;
    outcome: ExecuteValidatorOutcome;
}

interface SetFormValidationEvent {
    type: 'SET_FORM_VALIDATION';
    outcome: 'error' | 'success';
    outcomes: Record<string, ExecuteValidatorOutcome>;
}

interface SetInitialValidationEvent {
    type: 'SET_INITIAL_VALIDATION';
    outcomes: Array<{ name: string; outcome: ExecuteValidatorOutcome }>;
}

interface ResetEvent {
    type: 'RESET';
    initialFields: FieldInputMap;
}

interface SubmitEvent {
    type: 'SUBMIT';
}

type Event =
    | ChangeEvent
    | CommitEvent
    | FastCommitEvent
    | SetFieldValidationEvent
    | SetFormValidationEvent
    | SetInitialValidationEvent
    | SubmitEvent
    | ResetEvent;

function reducer(state: State, event: Event): State {
    // Optimization: Filter all effects that are no longer idle.
    // Prevents them from being needlessly iterated through again.
    const prevEffects = state.effects.filter(isEffectIdle);

    switch (state.status) {
        case 'idle':
        case 'error':
            if (event.type === 'CHANGE') {
                const fields = updateField(state.fields, event.name, { value: event.value, status: 'active' });
                return {
                    ...state,
                    fields,
                    effects: prevEffects,
                    status: 'idle',
                };
            }

            if (event.type === 'FAST_COMMIT') {
                const fields = updateField(state.fields, event.name, { value: event.value, status: 'idle' });
                return {
                    ...state,
                    fields,
                    status: 'idle',
                    effects: prevEffects,
                };
            }

            if (event.type === 'COMMIT') {
                const fields = updateField(state.fields, event.name, { value: event.value, status: 'validating' });
                return {
                    ...state,
                    fields,
                    status: 'idle',
                    effects: isEmpty(event.value)
                        ? prevEffects
                        : [...prevEffects, createEffect('onFieldInteraction', { field: fields[event.name] })],
                };
            }

            if (event.type === 'SET_FIELD_VALIDATION') {
                const fields = updateField(state.fields, event.name, {
                    message: event.outcome.message,
                    status: event.outcome.status,
                });
                return {
                    ...state,
                    fields,
                    status: 'idle',
                    effects: [...prevEffects, createEffect('onFieldValidation', { field: fields[event.name] })],
                };
            }

            if (event.type === 'SET_INITIAL_VALIDATION') {
                let fields = state.fields;
                for (const { name, outcome } of event.outcomes) {
                    fields = updateField(fields, name, {
                        message: outcome.message,
                        status: outcome.status,
                    });
                }

                return {
                    ...state,
                    fields,
                };
            }

            if (event.type === 'RESET') {
                return {
                    ...state,
                    fields: resetFields(state.fields, event.initialFields),
                    effects: prevEffects,
                };
            }

            if (event.type === 'SUBMIT') {
                return {
                    ...state,
                    effects: prevEffects,
                    status: 'validating',
                };
            }

            return state;
        case 'validating':
            if (event.type === 'SET_FORM_VALIDATION') {
                const fields = Object.keys(state.fields).reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: {
                            ...state.fields[key],
                            status: event.outcomes[key].status,
                            message: event.outcomes[key].message,
                        },
                    };
                }, {});

                const nextEffects =
                    event.outcome === 'success'
                        ? [createEffect('onSubmit', { values: gatherFieldValues(fields) }), createEffect('resetFields')]
                        : [createEffect('onFormValidationError', { fields })];

                return {
                    ...state,
                    fields,
                    status: 'idle',
                    effects: [...prevEffects, ...nextEffects],
                };
            }

            return state;
        default:
            return state;
    }
}

interface SchemaValidator {
    field: (name: string, values: Record<string, FieldValue>) => ExecuteValidatorOutcome;
    form: (fields: FieldMap, values: Record<string, FieldValue>) => Record<string, ExecuteValidatorOutcome>;
    fieldAsync: (name: string, values: Record<string, FieldValue>) => Promise<ExecuteValidatorOutcome>;
    formAsync: (
        fields: FieldMap,
        values: Record<string, FieldValue>
    ) => Promise<Record<string, ExecuteValidatorOutcome>>;
    runAsync?: boolean;
}

interface UseFormOptions extends SideEffects {
    /**
     * The fields in the form
     */
    fields: FieldInputMap;
    /**
     * contains validation for form and field and returns a promise
     */
    schemaValidator?: SchemaValidator;
    resetOnSubmit?: boolean;
    /** Validates all the fields on mount and puts them in the correct state */
    validateOnMount?: boolean;
}

export function useForm(options: UseFormOptions) {
    const [state, dispatch] = useReducer<Reducer<State, Event>>(reducer, {
        fields: convertFieldInputsToFields(options.fields),
        status: 'idle',
        effects: [],
    });

    const { fields, effects } = state;
    const {
        fields: inputFields,
        onFieldInteraction,
        onFieldValidation,
        onFormValidationError,
        schemaValidator,
        resetOnSubmit = false,
        validateOnMount = false,
        onSubmit,
    } = options;

    const getFieldValidators = useCallback(
        (name: string) => {
            return inputFields[name]?.validators;
        },
        [inputFields]
    );

    const getFieldFormatters = useCallback(
        (name: string) => {
            return inputFields[name]?.formatter;
        },
        [inputFields]
    );

    const getFieldMetadata = useCallback(
        (name: string) => {
            return inputFields[name]?.meta;
        },
        [inputFields]
    );

    const resetFields = useCallback(() => {
        if (resetOnSubmit) {
            dispatch({ type: 'RESET', initialFields: options.fields });
        }
    }, [resetOnSubmit, options.fields]);

    useSideEffects(
        { effects },
        { onFieldInteraction, onFieldValidation, onFormValidationError, onSubmit },
        getFieldMetadata,
        resetFields
    );

    useIsomorphicLayoutEffect(() => {
        // The following solution only works if you are using the built in validator.
        // If a yup-schema is passed we bail early.
        if (!validateOnMount || schemaValidator) {
            return;
        }

        const outcomes: Array<{ name: string; outcome: ExecuteValidatorOutcome }> = [];
        for (const [name, field] of Object.entries(inputFields)) {
            const validators = getFieldValidators(name);
            const formatter = getFieldFormatters(name);

            if (!validators) {
                continue;
            }

            const value = runFormatter(field.value, formatter);
            if (isEmpty(value)) {
                continue;
            }

            const validationResult = executeValidators(validators, value, gatherFieldValues(fields));
            outcomes.push({
                name: name,
                outcome: validationResult,
            });
        }

        dispatch({ type: 'SET_INITIAL_VALIDATION', outcomes });
    }, []);

    const validateField = useCallback(
        (name: string, value: FieldValue) => {
            if (schemaValidator) {
                const allValues = { ...gatherFieldValues(fields), ...{ [name]: value } };
                if (schemaValidator.runAsync) {
                    schemaValidator.fieldAsync(name, allValues).then((outcome: ExecuteValidatorOutcome) =>
                        dispatch({
                            type: 'SET_FIELD_VALIDATION',
                            name,
                            outcome,
                        })
                    );
                } else {
                    const outcome = schemaValidator.field(name, allValues);
                    dispatch({
                        type: 'SET_FIELD_VALIDATION',
                        name,
                        outcome,
                    });
                }
            } else {
                const validators = getFieldValidators(name);
                const formatter = getFieldFormatters(name);

                if (!validators) return;

                const allValues = { ...gatherFieldValues(fields), ...{ [name]: value } };
                const formattedValue = runFormatter(value, formatter);
                const outcome = executeValidators(validators, formattedValue, allValues);

                dispatch({
                    type: 'SET_FIELD_VALIDATION',
                    name,
                    outcome,
                });
            }
        },
        [fields, getFieldFormatters, getFieldValidators]
    );

    const validateForm = useCallback(() => {
        const allValues = gatherFieldValues(fields);
        if (schemaValidator) {
            if (schemaValidator.runAsync) {
                schemaValidator.formAsync(fields, allValues).then((outcomes: Record<string, ExecuteValidatorOutcome>) =>
                    dispatch({
                        type: 'SET_FORM_VALIDATION',
                        outcome: doesErrorExist(outcomes) ? 'error' : 'success',
                        outcomes,
                    })
                );
            } else {
                const outcomes = schemaValidator.form(fields, allValues);
                dispatch({
                    type: 'SET_FORM_VALIDATION',
                    outcome: doesErrorExist(outcomes) ? 'error' : 'success',
                    outcomes,
                });
            }
        } else {
            const outcomes: Record<string, ExecuteValidatorOutcome> = {};
            for (const field of Object.values(fields)) {
                const validators = getFieldValidators(field.name);
                const formatter = getFieldFormatters(field.name);

                if (!validators) {
                    outcomes[field.name] = { status: 'success' };
                    continue;
                }

                const value = runFormatter(field.value, formatter);
                const validationResult = executeValidators(validators, value, allValues);
                outcomes[field.name] = validationResult;
            }

            dispatch({
                type: 'SET_FORM_VALIDATION',
                outcome: doesErrorExist(outcomes) ? 'error' : 'success',
                outcomes,
            });
        }
    }, [fields, getFieldFormatters, getFieldValidators]);

    /* --- Public API --- */

    /**
     * Dispatches an internal submit event.
     * Should be called when you want to submit the form,
     * when dispatched validation for the entire form will run.
     * If validation is successful, the `onSubmit` function will be called.
     * If resetOnSubmit option is true, all form fields will be cleared/reset to an empty string
     * If validation errors, `onFormValidationError` will called.
     */
    const handleSubmit = useCallback(
        (event: React.FormEvent) => {
            if (event && event.preventDefault) {
                event.preventDefault();
            }

            dispatch({ type: 'SUBMIT' });

            validateForm();
        },
        [validateForm]
    );

    /**
     * Should be called when user is changing a value.
     */
    const changeValue = useCallback((name: string, value: FieldValue) => {
        dispatch({ type: 'CHANGE', name, value });
    }, []);

    /**
     * Should be called when the user intends to commit a value.
     * Validation will be triggered after the value has been committed.
     *
     * If no value is provided then the current value will be used during validation.
     */
    const commitValue = useCallback(
        (name: string, value?: FieldValue) => {
            const valueToCommit = isUndefinedOrNull(value) ? fields[name].value : value;
            dispatch({ type: 'COMMIT', name, value: valueToCommit });

            if (!isEmpty(valueToCommit)) {
                validateField(name, valueToCommit);
            }
        },
        [fields, validateField]
    );

    /**
     * Should be called when the user intends to commit a value.
     * The only difference between this and commitValue is that it bypasses validation.
     * Passing a value is also required.
     */
    const fastCommitValue = useCallback((name: string, value: FieldValue) => {
        dispatch({ type: 'FAST_COMMIT', name, value });
    }, []);

    /**
     * Returns the current value of the field.
     * @param name
     */
    const getFieldValue = useCallback(
        (name: string) => {
            return fields[name]?.value;
        },
        [fields]
    );

    /**
     * Returns the current message of the field.
     * @param name
     */
    const getFieldMessage = useCallback(
        (name: string) => {
            return fields[name]?.message;
        },
        [fields]
    );

    /**
     * Returns the current state of the field.
     * @param name
     */
    const getFieldState = useCallback(
        (name: string) => {
            return fields[name]?.status;
        },
        [fields]
    );

    /**
     * Prop getter for input fields.
     */
    const getInputProps = useCallback(
        (name: string, { onChange, onBlur } = {}) => {
            return {
                value: getFieldValue(name),
                onChange: callAll(onChange, (e: React.ChangeEvent<HTMLInputElement>) =>
                    changeValue(name, e.target.value)
                ),
                onBlur: callAll(onBlur, () => commitValue(name)),
            };
        },
        [getFieldValue, changeValue]
    );

    const getAllValues = useCallback(() => {
        return gatherFieldValues(fields);
    }, [fields]);

    return {
        handleSubmit,
        changeValue,
        commitValue,
        fastCommitValue,
        getFieldValue,
        getFieldState,
        getFieldMessage,
        getInputProps,
        getAllValues,
    };
}
