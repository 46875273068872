interface IbanValidationOptions {
    countries?: Array<string>;
}

export function isLuhn(input: string): boolean {
    const calc = input
        .split('')
        .map((n) => Number(n))
        .map((n, index) => (index % 2 === 0 ? n * 2 : n)) // Multiply the number w/ 1 or 2, depending on index
        .reduce((acc, c) => acc + c + (c > 9 ? -9 : 0), 0); // Add the current number sum to the accumulator

    return calc % 10 === 0;
}

/**
 * Returns true if the supplied value is an allowed occupation.
 */
export function validOccupation(allowedOccupations: string[]) {
    return (value: string): boolean => {
        return allowedOccupations.includes(value);
    };
}

/**
 * Returns true if the passed in value is at least or more than the second argument.
 */
export function atLeast(min: number) {
    return (value: number) => {
        let input = value;

        if (Number.isNaN(Number(value)) || input === undefined) {
            input = 0;
        }

        return Number(input) >= min;
    };
}

/**
 * Returns true if the supplied phone number is valid false if not.
 */
export function phone(input: string) {
    let number = input.toString();

    const numbersWhiteListed = ['11414'];
    const numberLengthMin = 7;
    const numberLengthMax = 10;

    const numberLength = number.length;

    // Check if number is white listed
    if (numbersWhiteListed.includes(number)) {
        return true;
    }

    // Check if the number of digits in the number is valid
    if (numberLength < numberLengthMin || numberLength > numberLengthMax) {
        return false;
    }

    return true;
}

/**
 * Returns true if the passed in value is not empty, undefined or null.
 */
function notEmpty(value: string) {
    return value !== undefined && value !== null && value !== '' && value.length !== 0;
}

const emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

/**
 * Returns true if the passed in value is an email and false if not.
 *
 * Inspired by: https://github.com/manishsaraan/email-validator/blob/master/index.js
 */
export function email(value: string) {
    if (!value) {
        return false;
    }
    const emailParts = value.split('@');
    if (emailParts.length !== 2) {
        return false;
    }
    const account = emailParts[0];
    const address = emailParts[1];
    if (account.length > 64) {
        return false;
    }
    if (address.length > 255) {
        return false;
    }
    const domainParts = address.split('.');
    if (domainParts.some((part) => part.length > 63)) {
        return false;
    }
    return emailRegex.test(value);
}

// https://gist.github.com/dperini/729294
const urlRegex = new RegExp(
    '^' +
        // protocol identifier (optional)
        // short syntax // still required
        '(?:(?:(?:https?|ftp):)?\\/\\/)' +
        // user:pass BasicAuth (optional)
        '(?:\\S+(?::\\S*)?@)?' +
        '(?:' +
        // IP address exclusion
        // private & local networks
        '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
        '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
        '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
        // IP address dotted notation octets
        // excludes loopback network 0.0.0.0
        // excludes reserved space >= 224.0.0.0
        // excludes network & broadcast addresses
        // (first & last IP address of each class)
        '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
        '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
        '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
        '|' +
        // host & domain names, may end with dot
        // can be replaced by a shortest alternative
        // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
        '(?:' +
        '(?:' +
        '[a-z0-9\\u00a1-\\uffff]' +
        '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
        ')?' +
        '[a-z0-9\\u00a1-\\uffff]\\.' +
        ')+' +
        // TLD identifier name, may end with dot
        '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
        ')' +
        // port number (optional)
        '(?::\\d{2,5})?' +
        // resource path (optional)
        '(?:[/?#]\\S*)?' +
        '$',
    'i'
);

/**
 * Returns true if the passed in value is a url and false if not.
 */
export function url(value: string) {
    return urlRegex.test(value);
}

/**
 * Returns true if the passed in value exists and false if not.
 */
export function required(value: string) {
    return notEmpty(value);
}

const numericRegex = /^[+-]?([0-9]*['.'])?[0-9]+$/;

/**
 * Returns true if the supplied value is only numbers.
 * Accepts both type string and number.
 */
export function numeric(value: string | number) {
    if (typeof value === 'string') {
        return numericRegex.test(value);
    }

    return typeof value === 'number';
}

export function minLength(min: number) {
    return (value: string) => {
        return notEmpty(value) && value.length >= min;
    };
}

export function maxLength(max: number) {
    return (value: string) => {
        return notEmpty(value) && value.length > max;
    };
}

/**
 * Returns true if the supplied value is the same length as the supplied length.
 */
export function length(len: number) {
    return (value: string) => {
        return notEmpty(value) && value.length === len;
    };
}

/**
 * Returns true if the supplied value is less or equal to the max value.
 */
export function maxValue(max: number) {
    return (value: number): boolean => {
        return value <= max;
    };
}

/**
 * Returns true if the supplied value is larger than the supplied value false if not.
 */
export function largerThan(min: number) {
    return (value: number) => {
        if (value === undefined || value === null) {
            return false;
        }

        return value > min;
    };
}

/**
 * Returns true if the supplied value is part of the supplied values false if not.
 */
export function oneOf(values: any[]) {
    return (value: string) => {
        return values.indexOf(value) >= 0;
    };
}

/**
 * Returns true if the supplied value is a match.
 */
export function match(data: any, field: any) {
    return (value: string) => {
        return data && value === data[field];
    };
}

/**
 * Returns true if the supplied text is included in the value.
 */
export function includes(value: string, text: string, position: number = 0) {
    if (!value || !text) {
        return false;
    }

    return value.includes(text, position);
}

/**
 * Accepts an array of validation functions and returns a new function
 * that when executed will return true if the value passes one ore more of the functions.
 */
export function any(validators: Function[]) {
    if (validators.constructor !== Array) {
        throw new Error('Validators must be an array of validation functions');
    }

    return (...args: any[]) => {
        return validators.some((func) => func(...args));
    };
}

/**
 * Accepts a string of IBAN and returns a boolean if it's valid or not
 */
export function isValidIBAN(iban: string, options?: IbanValidationOptions): boolean {
    if (!iban) {
        return false;
    }

    // Country codes lengths
    const codeLengths: { [key: string]: number } = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
    };

    // Char codes
    const A = 65;
    const Z = 90;

    // Remove spaces keeping only numbers and letters for processing
    let parsedIban = iban.replace(/\s+/g, '');

    // We should not allow wrong characters
    if (parsedIban.match(/[^A-Z0-9]/g)) {
        return false;
    }

    /**
     * Parsing IBAN to following format
     * [ _iban, _countryPrefix, _firstTwoDigits, _reminder ]
     */
    const code = parsedIban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code) {
        return false;
    }

    // If specified countries list
    if (options?.countries && !options.countries.includes(code[1])) {
        return false;
    }

    // Check if the IBAN has correct length using contry code
    if (parsedIban.length !== codeLengths[code[1]]) {
        return false;
    }

    // Move the four initial characters to the end of the string
    parsedIban = parsedIban.toUpperCase();
    parsedIban = parsedIban.substr(4) + parsedIban.substr(0, 4);

    // Replace each letter in the string with two digits, thereby expanding the string, where A = 10, B = 11, ..., Z = 35
    parsedIban = parsedIban
        .split('')
        .map((n) => {
            let charCode = n.charCodeAt(0);
            if (charCode >= A && charCode <= Z) {
                // A = 10, B = 11, ... Z = 35
                return charCode - A + 10;
            }
            return n;
        })
        .join('');

    // Interpret the string as a decimal integer and compute the remainder of that number on division by 97
    let remainder = parsedIban,
        block;

    while (remainder.length > 2) {
        block = remainder.slice(0, 9);
        remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
    }
    // If mod 97 === 1, valid
    return parseInt(remainder, 10) % 97 === 1;
}
