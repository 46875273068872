/**
 * Returns a function that will execute all the given functions, if defined.
 */
export function callAll(...fns: Function[]) {
    return function execute(...args: any) {
        fns.forEach((fn) => {
            if (fn) {
                fn(...args);
            }
        });
    };
}
