import { Field, FieldMap, FieldInputMap, FieldValue, FieldFormatter } from './types';
import { ExecuteValidatorOutcome } from './validators';

/**
 * Appends the internal properties to a user provided field.
 * @param fields
 */
export function convertFieldInputsToFields(fields: FieldInputMap): FieldMap {
    return Object.entries(fields).reduce((acc, [key, field]) => {
        return {
            ...acc,
            [key]: {
                name: key,
                message: undefined,
                value: field.value,
                status: 'idle',
            },
        };
    }, {});
}

/**
 * Gathers all values into an object with the name of the field as the key and the value as the value.
 * @param fields
 */
export function gatherFieldValues(fields: FieldMap): Record<string, FieldValue> {
    return Object.entries(fields).reduce((acc, [key, field]) => {
        return {
            ...acc,
            [key]: field.value,
        };
    }, {});
}

/**
 * Formats the value with the given formatter.
 */
export function runFormatter(rawValue: string, formatter?: FieldFormatter) {
    if (typeof formatter !== 'function') {
        return rawValue;
    }

    return formatter(rawValue);
}

/**
 * Returns true if one or more of the outcomes has an error.
 */
export function doesErrorExist(outcomes: Record<string, ExecuteValidatorOutcome>) {
    return Object.values(outcomes).some((outcome) => outcome.status === 'error');
}

/**
 * Updates the field with given properties.
 * Throws an error if the field does not exist.
 */
export function updateField(fields: FieldMap, name: string, field: Partial<Omit<Field, 'name'>>) {
    const f = fields[name];
    if (!f) {
        throw new Error(`No field exists with name: ${name}`);
    }

    return {
        ...fields,
        [name]: { ...f, ...field },
    };
}


/**
 * Resets all the fields to initial values.
 * Initial fields must be passed as a second argument to reset to initial values
 */
export function resetFields(fields: FieldMap, initialFields: FieldInputMap) {
    return Object.keys(fields).reduce((acc, key) => {
        return { ...acc, [key]: { ...fields[key], value: initialFields[key].value } };
    }, {});
}

