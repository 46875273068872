import { FieldValue } from './types';
import { isUndefined } from './utils/isUndefined';

/**
 * The outcome of validation can be one of the following.
 */
export type ValidationOutcome = 'success' | 'warning' | 'error';
/**
 * A field validator is a pure function that returns a validation outcome.
 */
export type FieldValidator = (value: FieldValue, values?: Record<string, FieldValue>) => boolean;

export interface FieldValidatorOutcome {
    severity: 'error' | 'warning';
    message?: string;
}

export type ExecuteValidator = (
    value: FieldValue,
    values: Record<string, FieldValue>
) => FieldValidatorOutcome | undefined;

export interface ExecuteValidatorOutcome {
    status: 'success' | 'error' | 'warning';
    message?: string;
}

/**
 * Returns a function that will execute the given validator.
 * If the validators is true, the inner function will return undefined.
 * If the validators is false, the inner function will return an object with the severity and the message.
 */
export function newValidator(fn: FieldValidator, severity: 'warning' | 'error', message?: string | (() => string)) {
    return function executeValidator(value: FieldValue, values: Record<string, FieldValue>) {
        const res = fn(value, values);
        if (res) {
            return undefined;
        }

        if (typeof message === 'function') {
            message = message();
        }

        return { severity, message };
    };
}

/**
 * Executes an array of validators.
 */
export function executeValidators(
    fns: ExecuteValidator[],
    value: FieldValue,
    values: Record<string, FieldValue>
): ExecuteValidatorOutcome {
    const errorStack: { status: 'error'; message?: string }[] = [];
    const warningStack: { status: 'warning'; message?: string }[] = [];

    for (const fn of fns) {
        const outcome = fn(value, values);
        if (isUndefined(outcome)) {
            continue;
        }
        if (outcome.severity === 'error') {
            errorStack.push({ status: 'error', message: outcome.message });
        }
        if (outcome.severity === 'warning') {
            warningStack.push({ status: 'warning', message: outcome.message });
        }
    }

    if (errorStack.length > 0) {
        return errorStack[0];
    }

    if (warningStack.length > 0) {
        return warningStack[0];
    }

    return { status: 'success' };
}
