import { isUndefinedOrNull } from './isUndefinedOrNull';
import { isString } from './isString';
import { isBoolean } from './isBoolean';
import { isNumber } from './isNumber';

export function isEmpty(arg: any) {
    if (isUndefinedOrNull(arg)) {
        return true;
    }

    if (isString(arg)) {
        return arg.length === 0;
    }

    if (isBoolean(arg)) {
        return false;
    }

    if (isNumber(arg)) {
        return false;
    }

    if (Array.isArray(arg)) {
        return false;
    }

    return Boolean(arg);
}
