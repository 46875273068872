import { useEffect } from 'react';
import { State } from './useForm';
import { Field } from './types';

export interface Effect {
    type: 'onFieldInteraction' | 'onFieldValidation' | 'onSubmit' | 'onFormValidationError' | 'resetFields';
    status: 'idle' | 'started';
    markAsStarted(): void;
    [key: string]: any;
}

type FieldWithMeta = Field & { meta: Record<string, any> };

export interface SideEffects {
    /**
     * Called after `commitValue`
     */
    onFieldInteraction?: (field: FieldWithMeta) => void;
    /**
     * Called after validation finished
     */
    onFieldValidation?: (field: FieldWithMeta) => void;
    /**
     * Called after form is submitted and validation passed.
     */
    onSubmit?: (values: Record<string, any>) => void;
    /**
     * Called after form is submitted and validation did not pass.
     */
    onFormValidationError?: (fields: Record<string, FieldWithMeta>) => void;
}

/**
 * Executes all the side effects that `useForm` exposes.
 */
export function useSideEffects(
    state: Pick<State, 'effects'>,
    sideEffects: SideEffects,
    getFieldMetadata: (name: string) => Record<string, any> | undefined,
    resetFields: () => void,
) {
    const { effects } = state;
    const { onFieldInteraction, onFieldValidation, onFormValidationError, onSubmit } = sideEffects;

    useEffect(() => {
        // We have no effects to execute.
        if (effects.length < 1) {
            return;
        }

        effects.forEach((effect) => {
            if (effect.status !== 'idle') {
                return;
            }

            if (effect.type === 'onSubmit' && onSubmit) {
                onSubmit(effect.values);
            }

            if (effect.type === 'resetFields' && resetFields) {
                resetFields()
            }

            if (effect.type === 'onFieldInteraction' && onFieldInteraction) {
                onFieldInteraction({ ...effect.field, meta: getFieldMetadata(effect.field.name) });
            }

            if (effect.type === 'onFieldValidation' && onFieldValidation) {
                onFieldValidation({ ...effect.field, meta: getFieldMetadata(effect.field.name) });
            }

            if (effect.type === 'onFormValidationError' && onFormValidationError) {
                onFormValidationError(effect.fields);
            }

            effect.markAsStarted();
        });
    }, [effects, getFieldMetadata, onFieldInteraction, onFieldValidation, onFormValidationError, resetFields, onSubmit]);
}

/**
 * Creates a new effect object.
 */
export function createEffect(type: Effect['type'], args: Record<string, any> = {}): Effect {
    return {
        type,
        ...args,
        status: 'idle',
        markAsStarted() {
            this.status = 'started';
        },
    };
}

/**
 * Checks if the given effect is idle.
 */
export function isEffectIdle(effect: Effect) {
    return effect.status === 'idle';
}
